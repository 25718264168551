import React from 'react'
import SEO from '../components/seo'
import Layout from '../components/layout';
import NewsCarousel from '../components/news-page/news-carousel'
import { graphql, StaticQuery } from 'gatsby' 

const News = ({location}) => {
    return (

        <StaticQuery
        query={graphql`
        query NewsIndexQuery {
            allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "news"}}}){
                edges {
                    node {
                        id
                        frontmatter {
                            date
                            templateKey
                            image
                            url
                            title
                            description
                        }
                    }
                }
            }
            site {
                siteMetadata {
                  title
                  env
                  imagesPrefix
                }
            }
        }
        `}
        render={data => (
            <Layout location={location}>
                <div className="news-page-wrapper">
                    <SEO title='News' description='News of blix' />
                    <NewsCarousel data={data}/>
                </div>
            </Layout>
        )}
        />
    );
}

export default News;