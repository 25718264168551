import React from "react"
import { Container, Grid, Typography, Box } from "@material-ui/core"

class NewsCarousel extends React.Component {
  render() {
    return (
      <div>
        <div className="news-wrapper">
          <Container maxWidth="lg">
            <Typography
              variant="h3"
              align="center"
              color="textPrimary"
              style={{ marginTop: "20px" }}
            >
              News & Media
            </Typography>

            {this.props.data.allMarkdownRemark.edges
              .sort(
                (a, b) =>
                  new Date(b.node.frontmatter.date) -
                  new Date(a.node.frontmatter.date)
              )
              .map((post, i) => {
                return (
                  <div key={i}>
                    {i % 2 === 0 ? (
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        style={{ marginTop: "50px" }}
                        spacing={7}
                      >
                        <Grid
                          key={post.node.id}
                          item
                          md={6}
                          sm={6}
                          xs={12}
                          style={{ textAlign: "center" }}
                        >
                          <a href={post.node.frontmatter.url}>
                            <img
                              style={{ width: "50%" }}
                              src={this.props.data.site.siteMetadata.imagesPrefix + post.node.frontmatter.image}
                              alt={post.node.frontmatter.title}
                            />
                          </a>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <a
                            href={post.node.frontmatter.url}
                            style={{
                              color: "#000",
                              fontSize: "23px",
                              textDecoration: "none",
                            }}
                          >
                            {post.node.frontmatter.title}
                          </a>
                          <div style={{ marginTop: "10px" }}>
                            <a
                              href={post.node.frontmatter.url}
                              style={{
                                color: "#000",
                                fontSize: "17px",
                                textDecoration: "none",
                              }}
                            >
                              {post.node.frontmatter.description}
                            </a>
                          </div>
                          <div style={{ marginTop: "10px" }}>
                            <a
                              href={post.node.frontmatter.url}
                              style={{
                                fontSize: "17px",
                                textDecoration: "none",
                                background: "#4571C0",
                                color: "#fff",
                                padding: "7px",
                              }}
                            >
                              Read More
                            </a>
                          </div>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        style={{ marginTop: "50px" }}
                        spacing={7}
                      >
                        <Box clone order={{ xs: 2, sm: 2, md: 1, lg: 1 }}>
                          <Grid item md={6} sm={12} xs={12}>
                            <a
                              href={post.node.frontmatter.url}
                              style={{
                                color: "#000",
                                fontSize: "23px",
                                textDecoration: "none",
                              }}
                            >
                              {post.node.frontmatter.title}
                            </a>
                            <div style={{ marginTop: "10px" }}>
                              <a
                                href={post.node.frontmatter.url}
                                style={{
                                  color: "#000",
                                  fontSize: "17px",
                                  textDecoration: "none",
                                }}
                              >
                                {post.node.frontmatter.description}
                              </a>
                            </div>
                            <div style={{ marginTop: "10px" }}>
                              <a
                                href={post.node.frontmatter.url}
                                style={{
                                  fontSize: "17px",
                                  textDecoration: "none",
                                  background: "#4571C0",
                                  color: "#fff",
                                  padding: "7px",
                                }}
                              >
                                Read More
                              </a>
                            </div>
                          </Grid>
                        </Box>
                        <Box clone order={{ xs: 1, sm: 1, md: 2, lg: 2 }}>
                          <Grid
                            key={post.node.id}
                            item
                            md={6}
                            sm={6}
                            xs={12}
                            style={{ textAlign: "center" }}
                          >
                            <a href={post.node.frontmatter.url}>
                              <img
                                style={{ width: "50%" }}
                                src={this.props.data.site.siteMetadata.imagesPrefix + post.node.frontmatter.image}
                                alt={post.node.frontmatter.title}
                              />
                            </a>
                          </Grid>
                        </Box>
                      </Grid>
                    )}
                  </div>
                )
              })}
          </Container>
        </div>
      </div>
    )
  }
}

export default NewsCarousel
